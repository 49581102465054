import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { get, head, isNil } from '../../lib/nodash';
import { track, identify } from '../../lib/analytics';

import { APPLY_DISCOUNT_CODE } from '../../queries/cartQueries';
import { COUPON_APPLIED } from '../../lib/analytics/segmentActions';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import {
  setDiscountCode,
  setDiscountCodeAmount,
  setFailedDiscountCode,
} from '../../state/cart/cartSlice';
import deNodify from '../../lib/deNodify';

function useApplyDiscountCode() {
  const cartId = useSelector((state) => state.cart.cartId);
  const dispatch = useDispatch();
  const [applyDiscountCodeMutation, { loading, data }] = useMutation(
    APPLY_DISCOUNT_CODE,
    {
      refetchQueries: ['Cart'],
      client: shopifyClient,
    }
  );

  const applyDiscountCode = async (discountCode, options = {}) => {
    const { campaignName } = options;
    await applyDiscountCodeMutation({
      variables: {
        cartId,
        discountCodes: [discountCode],
      },
      onError: () => {
        dispatch(setFailedDiscountCode(discountCode));
        dispatch(setDiscountCodeAmount(null));
      },
      onCompleted: (res) => {
        const { cost } = res.cartDiscountCodesUpdate.cart;
        const discountValue =
          parseFloat(cost.checkoutChargeAmount.amount) -
          parseFloat(cost.subtotalAmount.amount);

        dispatch(setDiscountCode(discountCode));
        const formattedDiscountValue = get('percentage', discountAmount)
          ? `${discountValue}%`
          : `$${discountValue}`;
        if (discountValue > 0) {
          dispatch(setDiscountCodeAmount(formattedDiscountValue));
        }

        track(COUPON_APPLIED, {
          cart_id: cartId,
          coupon_id: discountCode,
          coupon_name: campaignName,
          discount: parseInt(discountValue),
          is_campaign: !isNil(campaignName),
        });
        identify({
          $discount_code: discountCode,
        });
      },
    });

    return discountCode;
  };

  return { applyDiscountCode, loading, data };
}

export default useApplyDiscountCode;
